.bottom-nav-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  padding: 5px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid lightgray;
  border-radius: 10px 10px 0 0;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
}

.bottom-nav-item {
  display: flex;
  align-items: center; /* Align items horizontally */
  cursor: pointer;
}

.nav-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.3s;
  position: relative !important;
}

.nav-icon {
  width: 18px;
  height: 18px;
  fill: #666;
}

.nav-text {
  color: #666;
  margin-left: 8px; /* Adjust margin as needed */
}

.nav-text.active {
  color: #d5ab17; /* Yellow color for active state text */
}

.bottom-nav-container.my {
  padding: 10px 0;
}

.bottom-nav-container.en {
  padding: 10px 0;
}

.bottom-nav-container.my .nav-text {
  margin: 0;
}

.bottom-nav-container.en .nav-text {
  margin: 0;
}

.bottom-nav-container.my .nav-icon {
  width: 34px !important;
  height: auto !important;
}

.bottom-nav-container.en .nav-icon {
  width: 34px !important;
  height: auto !important;
}
