.patient_name {
  font-size: 1.2rem;
}

.gender {
  height: 1px;
}

.medical_information {
  margin-left: 1rem;
  margin-top: 15px;
  font-size: 1.2rem;
}

.dietitian_report {
  margin-left: 1rem;
  margin-top: 15px;
  font-size: 1.2rem;
}

.patient_progress {
  margin-left: 1rem;
  margin-top: 15px;
  font-size: 1.2rem;
}

.upcoming_consultation {
  margin-left: 1rem;
  color: #468ff3;
  font-size: 1.2rem;
}

.date {
  margin-left: 1rem;
  font-size: 1.1rem;
  margin-top: 1%;
  height: 1px;
}

.dates {
  margin-left: 0.5rem;
  font-size: 1.1rem;
  height: 1px;
}

.time {
  margin-left: 0.5rem;
  font-size: 1.1rem;
  height: 1px;
}

.bootstrapBtn {
  position: absolute;
  bottom: 0.001rem;
  background-color: #29abe2;
  margin-left: 2%;
  margin-right: 13%;
  margin-top: 1rem;
  width: 100%;
}

.profile-box-wrapper {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  text-align: left;
  margin-bottom: 15px;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 8px;
  padding: 14px;
  box-shadow: 0px 3px 4px 1px #969696;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-text-render {
  position: absolute;
  top: 35%;
}

.main-header-text {
  margin-top: 1rem;
  font-size: 1.7rem;
  width: 65%;
  font-weight: 800;
  line-height: 1.1;
  color: #000;
}

.trial-header-text {
  color: rgba(24, 71, 136, 1);
  font-size: 0.8rem;
  font-weight: 700;
}

.image-banner-module {
  height: 200px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 0 0 0 40px;
}

.tertiary-button {
  background: #f9df81 !important;
  border-radius: 5px !important;
  width: 4em !important;
  color: #202020 !important;
  font-size: 16px !important;
  border: none !important;
  font-weight: 500 !important;
  padding: 2px 20px !important;
  margin: 2px;
}

.button-group-render {
  display: flex;
  justify-content: space-between;
}

.button-modul {
  background: #ede8d1;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 5px;
  width: 100px;
}

.button-modul-icon {
  height: 2.5rem;
  object-fit: contain;
}

.bottom-nav-group {
  display: flex;
  background: white;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px;
  border-radius: 10px;
  border-top: 1px solid #d4d4d4;
}

.nav-icon {
  height: 2rem;
  object-fit: contain;
  margin-right: 0.6em;
}

.dc-personal-img {
  display: flex;
  align-items: center;
}

.dc-personal-img-icon {
  width: 20px;
  height: auto;
  margin-right: 10px;
}

.dc-personal-img-text {
  margin: 5px 10px;
  font-weight: 800;
  font-size: 0.8rem;
  transform: scaleY(1.1);
}

.pt-form-reg-page {
  border: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0 !important;
  font-style: normal;
  font-weight: 600 !important;
}

.pt-form-label-font {
  font-size: 0.9rem;
  font-weight: 600;
}

.pt-readonly-input {
  background-color: transparent !important;
}

.pt-form-input {
  background-color: transparent !important;
  border: 1px solid #d5ab17 !important;
  border-radius: 100px !important;
  padding: 5px 15px;
}

.pt-form-input:focus {
  outline: none !important;
  border: 2px solid #d5ab17 !important;
  box-shadow: none !important;
}

.pt-form-input::placeholder {
  color: darkgray;
  font-size: 0.9rem;
  font-weight: 600;
}

.buttonNavBar {
  border: 1px solid lightgray;
  border-radius: 10px;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
}

.custom-date-picker {
  position: relative;
  display: inline-block;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.date-input {
  width: 21em;
  padding-right: 2rem;
  border-radius: 15px;
  margin: 2px;
}

.calendar-icon {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.primary-button-border-gold-family {
  background: #fff;
  border-radius: 5px;
  width: 100%;
  color: black;
  font-size: 16px;
  border: 1px solid #d5ab17;
  font-weight: 700;
  padding: 0px 20px;
}

.tag-name-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  padding: 5px 10px;
}

.tag-name-holder-button {
  border-radius: 20px;
  margin: 0;
}

.close-fam-member-lname {
  font-weight: 600;
}

.close-fam-member-lname > p {
  margin: 0;
}

.close-fam-member-icon {
  width: auto;
  height: 12px !important;
  margin-right: 10px;
}

.wrapper {
  text-align: center;
}

.close-fam-member-add {
  font-size: 0.9rem;
  font-weight: 700 !important;
  text-align: center;
  border-bottom: 2px solid black;
  display: inline-block;
  padding-bottom: 2px;
}

.header-text {
  color: black;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
}

.ftr-patient-textarea {
  border: 1px solid rgba(210, 210, 210, 1) !important;
  border-radius: 5px !important;
  padding: 5px 15px !important;
  margin: 2px !important;
}

.ftr-patient-select-year {
  border: 1px solid rgba(213, 171, 23, 1) !important;
  border-radius: 50px !important;
  padding: 5px 15px !important;
  margin: 2px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url('../images/DLP_revamp_asset/Icon/chevron-icon.svg');
  background-repeat: no-repeat;
  background-position: right 17px center;
  background-size: 12px;
}

.ftr-patient-select-year::-ms-expand {
  display: none;
}

.format-file-patient-ftr {
  font-size: 12px;
  margin: 0;
}

.upload-file-patient-ftr {
  font-size: 1.5rem;
  color: rgba(32, 32, 32, 1);
  cursor: pointer;
  font-weight: 900;
}

.update-lab-report-here {
  font-size: 0.9rem;
  font-weight: 800;
  color: rgba(32, 32, 32, 1);
  line-height: 19px;
}

@media only screen and (max-width: 350px) {
  .main-header-text {
    font-size: 1.4rem;
  }
}
