.custom-question {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.image-container {
  margin-right: 20px;
}

.image-container img {
  max-width: 100px;
  max-height: 100px;
}

.question-container {
  flex-grow: 1;
}

.title-image img {
  max-width: 100%;
  height: auto;
}

.description {
  margin-bottom: 10px;
}

.custom-title {
  flex: 0 0 30%;
  max-width: 30%;
}

.custom-description {
  text-align: left !important;
  font-style: normal !important;
  line-height: 1.2;
}

.custom-header-class {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.custom-content {
  position: absolute;
  right: 5%;
  width: 63%;
}

/* for module 8 view answer only */
.custom-title-m8 {
  max-width: 100%;
}
.custom-header-class-m8 {
  display: block;
  width: 100%;
}
.custom-content-m8 {
  position: relative;
  left: 5%;
  width: 90%;
  margin-top: 2.5rem;
  text-align: center;
}

.custom-description-8 {
  text-align: center !important;
}

.sv-root-modern .sv-dropdown {
  margin-top: -2.3rem;
}

.module8-dropdown .sv-dropdown {
  margin-top: 0;
  width: 100%;
}

/* Small Devices, Tablets */
@media only screen and (min-width: 500px) {
  .custom-content {
    position: absolute;
    left: 35%;
    width: 40%;
  }

  .sv-root-modern .sv-dropdown {
    margin-left: 5rem;
    width: 65%;
  }
}
