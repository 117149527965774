/*contact help footer*/

.en .main-footer {
    background-color: #e4ffe8;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my .main-footer {
    background-color: #e4ffe8;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.en .footer-info {
    margin-left: -1rem;
}

.my .footer-info {
    margin-left: -2rem;
}

.en .footer-info-business {
    display: flex;
    font-size: 0.6rem !important;
    flex-direction: column;
    flex-grow: 1;
    line-height: 0.8rem;
}

.my .footer-info-business {
    display: flex;
    font-size: 0.6rem !important;
    flex-direction: column;
    flex-grow: 1;
    line-height: 0.8rem;
}

.en .footer-info-business b,
.my .footer-info-business b {
    font-size: 0.6rem !important;
    font-weight: 600 !important;
}

.en .helpdesk-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
}

.my .helpdesk-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
}

.en .footer-info h1,
.my .footer-info h1 {
    font-size: 0.9rem !important;
    font-weight: 800 !important;
    color: #00af15 !important;
    padding: 0;
}